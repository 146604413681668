export const capitalize = function (string) {
  return string
    .split(" ")
    .map((word) => {
      const firstWord = word.slice(0, 1).toUpperCase();
      const restWord = word.slice(1, word.length).toLowerCase();
      return `${firstWord}${restWord}`;
    })
    .join(" ");
};

export const getCurrentProduct = (productId, data) => {
  return data.find((product) => product.productID === productId);
};

export const viewProduct = function (product) {
  const http = window.location.href.split(":")[0];
  const url = `${http}://${window.location.host}/product-details.html?${product}`;
  location.href = url;
};

export const jsProductLink = function () {
  if (!document.querySelector("div[data-trigger='product-link']")) return;
  const allProductTriggers = document.querySelectorAll(
    "div[data-trigger='product-link']"
  );
  allProductTriggers.forEach((product) => {
    product.addEventListener("click", () =>
      viewProduct(product.dataset.target)
    );
  });
};
