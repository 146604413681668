const footerMarkup = () => {
  return `    <div class="footer">
  <div class="footer__col footer__col--1">
    <img class="footer__logo" src="/img/New Millenium Logo.webp" alt="">
    <div class="footer__description">Millenium Hardware is manufacturer and exporter of wide range of quality stainless steel, brass, and aluminium hardware fittings</div>
  </div>

  <div class="footer__col footer__col--2">
    <div class="footer__links-block">
      <h3 class="footer__block-title">Stainless Steel Range</h3>
      <ul class="footer__links">
        <li class="footer__link"><a href="/stainless-steel/hinges.html" class="footer__link--a">Hinges</a></li>
        <li class="footer__link"><a href="/stainless-steel/tower-bolts.html" class="footer__link--a">Tower Bolts</a></li>
        <li class="footer__link"><a href="/stainless-steel/handles.html" class="footer__link--a">Handels</a></li>
        <li class="footer__link"><a href="/stainless-steel/knobs.html" class="footer__link--a">Knobs</a></li>
        <li class="footer__link"><a href="/stainless-steel/door-and-window-fittings.html" class="footer__link--a">Door & Window Fittings</a></li>
        <li class="footer__link"><a href="/stainless-steel/glass-fittings.html" class="footer__link--a">Glass Fittings</a></li>
        <li class="footer__link"><a href="/stainless-steel-range.html" class="footer__link--a">View All</a></li>
      </ul>
    </div>
  </div>

  <div class="footer__col footer__col--3">
    <div class="footer__links-block">
      <h3 class="footer__block-title">Aluminium Range</h3>
      <ul class="footer__links">
        <li class="footer__link"><a href="/aluminium/tower-bolts.html" class="footer__link--a">Tower Bolts</a></li>
        <li class="footer__link"><a href="/aluminium/glass-fittings.html" class="footer__link--a">Glass Fittings</a></li>
        <li class="footer__link"><a href="/aluminium/other-accessories.html" class="footer__link--a">Other Accessories</a></li>
        <li class="footer__link"><a href="/aluminium-range.html" class="footer__link--a">View All</a></li>
      </ul>
    </div>

    <div class="footer__links-block">
      <h3 class="footer__block-title">Quick Links</h3>
      <ul class="footer__links">
        <li class="footer__link"><a href="/about-us.html" class="footer__link--a">About Us</a></li>
        
        <li class="footer__link"><a href="/contact-us.html" class="footer__link--a">Contact Us</a></li>

        <li class="footer__link"><a href="/broucher.html" class="footer__link--a">Brouchers</a></li>

        <li class="footer__link"><a href="/contact-us.html" class="footer__link--a">Privacy Policy</a></li>
      </ul>
    </div>
  </div>

  <div class="footer__col footer__col--4">
    <div class="our-headquarter__content footer__headquarter">
      <h3 class="heading-3 footer__block-title">Our Headquarter</h3>
      <div class="footer__address">
      Shed No. 132,142,143A, nr. Kavery Dairy, G.I.D.C. Phase 2, Dared,
      <strong>Jamnagar, Gujarat 361006</strong>
      </div>
      <div class="footer__basic-contact">
      <a href="tel:+91 78747 87850">
        <svg>
          <use xlink:href="/img/general-icons.svg#icon-call"></use>
        </svg><span>+91 78747 87850</span>
      </a>
      <a href="mailto:millenium.exim@gmail.com">
      <svg>
      <use xlink:href="/img/general-icons.svg#icon-envelope"></use>
      </svg><span>millenium.exim@gmail.com</span>
    </a>
      </div>
      <div class="our-headquarter__contact social-links">
        <h3 class="heading-3 footer__block-title">Reach Out To Us On</h3>
      </div>
    </div>
  </div>
</div>
<div class="footer__copywrite">
  © 2023 All Right Reserved by Millenium Hardwares |  <a style="color:#f9f9f9" href="https://www.linkedin.com/in/abde-ali-vania-62323089/" target="_blank"> Designed By Abdeali</a>
</div>
<a href="/sitelinks.html" class="display-none">Site Links</a>`;
};

export const insertFooter = function () {
  if (!document.querySelector("footer")) return;

  const footerContent = footerMarkup();

  document
    .querySelector("footer")
    .insertAdjacentHTML("beforeend", footerContent);
};
