const coreHeader = `
<div class="header__wrapper">
<div class="header__logo">
  <a href="/"><img src="/img/New Millenium Logo.png" alt=""></a>
</div>
<ul class="header__items">
<li class="header__item header__item--product"><span class="header__item-a">Products</span>
<ul class="header__products">
</ul>

</li>
  <li class="header__item"><a class="header__item-a" href="/about-us.html">About Us</a></li>
  </li>
  <li class="header__item"><a class="header__item-a" href="/broucher.html">Brouchers</a></li>
  <li class="header__item"><a class="header__item-a" href="/contact-us.html">Contact Us</a></li>
</ul>
</div>`;

const headerItemHome = `
<li class="header__product"><a class="header__product-a" href="/stainless-steel-range.html">
<div class="header__productrange">
  <img src="/img/millenium-stainlesssteel.webp" alt="Icon Mullenium Stainless Steel Range" class="header__productrange-icon">
  <span class="header__productrange-label">Explore The Product Range</span>
</div>
</a></li>
<li class="header__product"><a class="header__product-a" href="/aluminium-range.html">
<div class="header__productrange">
  <img src="/img/millenium-alluminum.webp" alt="Icon Mullenium Alluminum Range" class="header__productrange-icon">
  <span class="header__productrange-label">Explore The Product Range</span>
</div>
</a></li>
<li class="header__product"><a class="header__product-a" href="">
<div class="header__productrange">
  <img src="/img/millenium-brass.webp" alt="Icon Mullenium Brass Range" class="header__productrange-icon">
  <span class="header__productrange-label">Explore The Product Range</span>
</div>
</a></li>`;

const headerProductContent = `
<li class="header__category-wrapper">
<div class="header__product-category">
  <span class="header__category-name">Stainless Steel Range</span>
  <ul class="header__category-list">
    <li class="header__category-item"><a href="/stainless-steel/hinges.html">Hinges</a></li>
    <li class="header__category-item"><a href="/stainless-steel/door-and-window-fittings.html">Door and Window Fittings</a></li>
    <li class="header__category-item"><a href="/stainless-steel/handles.html">Door & Cabinate Handles</a></li>
    <li class="header__category-item"><a href="/stainless-steel/tower-bolts.html">Towerbolts</a></li>
    <li class="header__category-item"><a href="/stainless-steel/knobs.html">Fancy Knobs</a></li>
    <li class="header__category-item"><a href="/stainless-steel/glass-fittings.html">Glass Fittings</a></li>
  </ul>
</div>
<div class="header__product-category">
  <span class="header__category-name">Alluminum Range</span>
  <ul class="header__category-list">
    <li class="header__category-item"><a href="/aluminium/glass-fittings.html">Glass Fittings</a></li>
    <li class="header__category-item"><a href="/aluminium/tower-bolts.html">Towerbolts</a></li>
    <li class="header__category-item"><a href="/aluminium/other-accessories.html">Other Accessories</a></li>
  </ul>
</div>
</li>
<li class="header__featured-items">
<div data-target="MLSB06" data-trigger="product-link" class="header__featured-card">
  
    <img src="/img/product-clean/006-3mm-bearing-flat-2-bearing-hinge-millenium-img.webp" alt="" class="header__featured-img">
    <span class="header__featured-title">3mm Flat Bearing Hing</span>
  
</div>
<div data-target="MLSPT6" data-trigger="product-link" class="header__featured-card">
  
  <img src="/img/product-clean/029-12mm-royal-tower-bolt-millenium-img.webp" alt="" class="header__featured-img">
  <span class="header__featured-title">12mm Royal Towerbolt</span>    
</div>
</li>`;

const mobileHeaderWrapper = `
<div class="mob-header__wrapper">
<div class="mob-header__logo">
  <a href="/"><img src="/img/New Millenium Logo.png" alt="" /></a>
</div>
<div class="mob-header__menu-btn">
  <div class="mob-header__menu-btn--line-1"></div>
  <div class="mob-header__menu-btn--line-2"></div>
  <div class="mob-header__menu-btn--line-3"></div>
</div>
</div>
`;

const mobileNav = `
<nav class="mob-header__nav">
<div class="mob-header__links">
  <div class="mob-header__link">
    <a class="mob-header__a" href="/about-us.html">About Us</a>
  </div>
  <div class="mob-header__link">
    <div
      class="mob-header__category list-expand"
      ><span class="mob-header__link-text">Stainless Steel Range</span
      ><span class="mob-header__arrow poppins--font">></span></div
    >
    <ul class="mob-header__catgory-list">
      <li class="mob-header__category-item">
        <a class="mob-header__a" href="/stainless-steel/hinges.html"
          >Hinges</a
        >
      </li>
      <li class="mob-header__category-item">
        <a class="mob-header__a" href="/stainless-steel/handles.html"
          >Handles</a
        >
      </li>
      <li class="mob-header__category-item">
        <a
          class="mob-header__a"
          href="/stainless-steel/tower-bolts.html"
          >Towerbolts</a
        >
      </li>
      <li class="mob-header__category-item">
        <a
          class="mob-header__a"
          href="/stainless-steel/glass-fittings.html"
          >Glass Fittings</a
        >
      </li>
      <li class="mob-header__category-item">
        <a
          class="mob-header__a"
          href="/stainless-steel/door-and-window-fittings.html"
          >Door & Window Fittings</a
        >
      </li>
      <li class="mob-header__category-item">
        <a class="mob-header__a" href="/stainless-steel/knobs.html"
          >Knobs</a
        >
      </li>
      <li class="mob-header__category-item">
        <a class="mob-header__a" href="/stainless-steel-range.html"
          >View All</a
        >
      </li>

    </ul>
  </div>
  <div class="mob-header__link">
    <div
      class="mob-header__category list-expand"
      ><span class="mob-header__link-text">Alluminum Range</span
      ><span class="mob-header__arrow">></span></div
    >
    <ul class="mob-header__catgory-list">
      <li class="mob-header__category-item">
        <a class="mob-header__a" href="/aluminium/tower-bolts.html"
          >Towerbolts</a
        >
      </li>
      <li class="mob-header__category-item">
        <a class="mob-header__a" href="/aluminium/glass-fittings.html"
          >Glass Fittings</a
        >
      </li>
      <li class="mob-header__category-item">
        <a
          class="mob-header__a"
          href="/aluminium/other-accessories.html"
          >Other Accessories</a
        >
      </li>
      <li class="mob-header__category-item">
        <a
          class="mob-header__a"
          href="/aluminium-range.html"
          >View All</a
        >
      </li>
    </ul>
  </div>
  <div class="mob-header__link">
  <a class="mob-header__a" href="/broucher.html">Brouchers</a>
  </div>
  <div class="mob-header__link">
    <a class="mob-header__a" href="/contact-us.html">Contact Us</a>
  </div>
</div>
</nav>
`;

export const insertHeader = function (headerName = "default") {
  if (!document.querySelector(".header")) return;
  const headerTarget = document.querySelector(".header");
  headerTarget.innerHTML = "";
  if (window.innerWidth > 850) {
    headerTarget.insertAdjacentHTML("beforeend", coreHeader);
    const productContainer = document.querySelector(".header__products");
    if (headerName === "default") {
      productContainer.insertAdjacentHTML("beforeend", headerProductContent);
    }
  } else {
    headerTarget.insertAdjacentHTML("beforeend", mobileHeaderWrapper);
    headerTarget.insertAdjacentHTML("beforeend", mobileNav);
  }
};

export const mobileHeaderInteraction = function () {
  if (!document.querySelector(".mob-header__wrapper")) return;
  const headerContainer = document.querySelector(".header");
  const toggleBtn = document.querySelector(".mob-header__menu-btn");
  const mainContent = document.querySelector("main");
  const clickAction = () => {
    headerContainer.classList.toggle("header--active");
  };
  const closeAction = () => {
    if (!headerContainer.classList.contains("header--active")) return;
    headerContainer.classList.remove("header--active");
  };
  toggleBtn.addEventListener("click", clickAction);

  mainContent.addEventListener("click", closeAction);

  /// Mobile header compress expand interaction

  const expandCompressBtn = document.querySelectorAll(".list-expand");

  expandCompressBtn.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      e.preventDefault();
      const serviceBlock = e.target.closest(".mob-header__link");
      const subServiceList = serviceBlock.querySelector("ul");

      serviceBlock.classList.toggle("expand-list--mobile");
    });
  });
};
