import { jsProductLink } from "../helper/helper";
import { insertFooter } from "./footer";
import { formSubmit } from "./formSubmit";
import { insertHeader, mobileHeaderInteraction } from "./header";
import { insertSocialLink } from "./socialLinks";

export const initComponent = function (header = "default") {
  insertFooter();
  insertHeader(header);
  mobileHeaderInteraction();
  insertSocialLink();
  formSubmit();
  jsProductLink();
};
