const socialMarkup = `              <ul class="social-icons__list">
<li class="social-icons__item icon--facebook">
  <a href="https://www.facebook.com/profile.php?id=100086597762122&mibextid=ZbWKwL">
    <svg class="social-icons__icon">
      <use
        xlink:href="/img/general-icons.svg#icon-facebook"
      ></use>
    </svg>
  </a>
  <span class="social-icons__label">Facebook</span>
</li>

<li class="social-icons__item icon--instagram">
  <a href="https://www.instagram.com/gulshan_brass/">
    <svg class="social-icons__icon">
      <use
        xlink:href="/img/general-icons.svg#icon-instagram"
      ></use>
    </svg>
  </a>
  <span class="social-icons__label">Instagram</span>
</li>

<li class="display-none social-icons__item icon--linkedin">
  <a href="">
    <svg class="social-icons__icon">
      <use
        xlink:href="/img/general-icons.svg#icon-linkedin"
      ></use>
    </svg>
  </a>
  <span class="social-icons__label">LinkedIn</span>
</li>

<li class="social-icons__item icon--whatsapp">
  <a href="https://wa.me/917874787850">
    <svg class="social-icons__icon">
      <use
        xlink:href="/img/general-icons.svg#icon-whatsapp"
      ></use>
    </svg>
  </a>
  <span class="social-icons__label">WhatsApp</span>
</li>

<li class="social-icons__item icon--call">
  <a href="tel:+917874787850">
    <svg class="social-icons__icon">
      <use xlink:href="/img/general-icons.svg#icon-call"></use>
    </svg>
  </a>
  <span class="social-icons__label">Call</span>
</li>

<li class="social-icons__item icon--mail">
  <a href="mailto:millenium.exim@gmail.com">
    <svg class="social-icons__icon">
      <use
        xlink:href="/img/general-icons.svg#icon-envelope"
      ></use>
    </svg>
  </a>
  <span class="social-icons__label">Email</span>
</li>
</ul>`;

export const insertSocialLink = function () {
  if (!document.querySelector(".social-links")) return;

  const allPlaceHolder = document.querySelectorAll(".social-links");
  allPlaceHolder.forEach((headerPlace) =>
    headerPlace.insertAdjacentHTML("beforeend", socialMarkup)
  );
};
