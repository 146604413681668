export const formSubmit = function () {
  if (!document.querySelector("form")) return;

  const form = document.getElementById("target__form");
  const result = document.getElementById("view__result");

  form.addEventListener("submit", function (e) {
    e.preventDefault();
    const formData = new FormData(form);
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);
    result.innerHTML = "Please wait...";
    console.log("form listber");
    fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    })
      .then(async (response) => {
        let json = await response.json();
        if (response.status == 200) {
          result.innerHTML = json.message;
          result.classList.add("form-success");
        } else {
          console.log(response);
          result.innerHTML = json.message;
          result.classList.add("form-caution");
        }
      })
      .catch((error) => {
        console.log(error);
        result.innerHTML = "Something went wrong!";
        result.classList.add("form-fail");
      })
      .then(function () {
        form.reset();
        setTimeout(() => {
          result.style.display = "none";
          result.classList.remove("form-success");
          result.classList.remove("form-caution");
          result.classList.remove("form-fail");
        }, 10000);
      });
  });
};
